.dataProtection {
  background: #fff;
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
  font-family: "ZEISSFrutigerNextW1G-Reg, sans-serif";
}
.dataProtection .WordSection {
  margin: 20px;
}
p.MsoNormal {
  background: white none repeat scroll 0% 50%; 
  -moz-background-clip: initial;
  -moz-background-origin: initial;
  -moz-background-inline-policy: initial;
   margin-bottom: 0.0001pt;
   line-height: normal;
   font-size: 11pt;
}
.dataProtection span.CommentSubjectChar {
  font-weight: bold;
}
.dataProtection .MsoChpDefault {
  font-size: 15px;
}
.dataProtection .MsoPapDefault {
  margin-bottom: 11px;
  line-height: 107%;
}
.dataProtection ol {
  margin-bottom: 0;
}
.dataProtection ul {
  margin-bottom: 0;
}
.MsoHyperlink{
  text-decoration: none;
  color: black;
}

.MsoHyperlink:hover{
  color: blue;
}
/* ul {
  list-style-type: none;
  color:black;
}
ul li:before {
  content: '\2014';
  position: absolute;
  margin-left: -20px;
} */

ul {
  margin: 0;
}
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: '\2014';
  position: absolute;
  margin-left: -20px;
}