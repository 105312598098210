@media screen and (min-width: 1025px) {
  .selectIcon {
    cursor: pointer;
  }
}
@media screen and (max-width: 1024px) {
  .selectIcon {
    cursor: auto;
  }
}
