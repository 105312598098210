/* Please becareful with below code changes , A tiny mistake may break responsiveness of application */
.react-datepicker-popper {
  z-index: 100000 !important;
}
@media screen and (max-height: 639px) {
  @media screen and (min-width: 960px) {
    .divDeviceListContainerHeight {
      min-height: calc(100% - 290px);
      max-height: calc(100% - 298px);
      overflow-y: auto;
    }
    .divDeviceListkeyContainerHeight {
      max-height: calc(100% - 226px);
      overflow-y: auto;
    }
    .divPenteroKeyContainerHeight {
      max-height: calc(100% - 226px);
      overflow-y: auto;
    }
    @media screen and (min-height: 600px) {
      .divDeviceListContainerHeight {
        min-height: calc(100% - 290px);
        max-height: calc(100% - 298px);
        overflow-y: auto;
      }
      .divDeviceListkeyContainerHeight {
        max-height: calc(100% - 226px);
        overflow-y: auto;
      }
      .divPenteroKeyContainerHeight {
        max-height: calc(100% - 226px);
        overflow-y: auto;
      }
    }
    @media screen and (max-height: 599px) {
      .divDeviceListContainerHeight {
        max-height: calc(100% - 120px);
        overflow-y: auto;
      }
      .divDeviceListkeyContainerHeight {
        max-height: calc(100% - 70px);
        overflow-y: auto;
      }
      .divPenteroKeyContainerHeight {
        min-height: calc(100% - 74px);
        max-height: calc(100% - 74px);
        overflow-y: auto;
      }
    }
  }
  @media screen and (min-width: 1113px) {
    @media screen and (min-height: 600px) {
      .divDeviceListContainerHeight {
        min-height: calc(100% - 160px);
        max-height: calc(100% - 287px);
        overflow-y: auto;
      }
      .divDeviceListkeyContainerHeight {
        max-height: calc(100% - 160px);
        overflow-y: auto;
      }
      .divPenteroKeyContainerHeight {
        min-height: calc(100% - 74px);
        max-height: calc(100% - 74px);
        overflow-y: auto;
      }
    }

    @media screen and (max-height: 599px) {
      .divDeviceListContainerHeight {
        height: 100%;
        max-height: calc(100% - 150px);
        overflow-y: auto;
      }
      .divDeviceListkeyContainerHeight {
        height: 100%;
        max-height: calc(100% - 150px);
        overflow-y: auto;
      }
      .divPenteroKeyContainerHeight {
        height: 100%;
        min-height: calc(100% - 74px);
        max-height: calc(100% - 74px);
        overflow-y: auto;
      }
    }
  }
}

@media screen and (min-height: 640px) {
  @media screen and (min-width: 960px) {
    .divDeviceListContainerHeight {
      max-height: 68%;
      overflow-y: auto;
    }
    .divDeviceListkeyContainerHeight {
      max-height: 76%;
      overflow-y: auto;
    }
    .divPenteroKeyContainerHeight {
      min-height: calc(100% - 73px);
      overflow-y: auto;
    }
  }
  @media screen and (min-width: 1113px) {
    .divDeviceListContainerHeight {
      min-height: calc(100% - 266px);
      /* max-height: calc(100% - 266px); */
      overflow-y: auto;
    }
    .divDeviceListkeyContainer {
      max-height: calc(100% - 266px);
      overflow-y: auto;
    }
  }
}

@media screen and (min-height: 641px) {
  @media screen and (max-width: 959px) {
    /* .fixedFooter {
      position: fixed;
      bottom: 0px;
      width: 100%;
    } */
  }
  @media screen and (min-width: 960px) {
    .divDeviceListContainerHeight {
      height: calc(100% - 298px);
    }
    .divPenteroKeyContainerHeight {
      height: calc(100% - 180px);
    }
  }
}

@media screen and (max-height: 470px) {
  @media screen and (max-width: 959px) {
    /* .fixedFooter {
      position: relative;
      bottom: 0px;
      width: 100%;
    } */
    .divDeviceListContainerHeight {
      padding-bottom: 0px !important;
    }
    .divDeviceListkeyContainerHeight {
      padding-bottom: 0px !important;
    }
    .divPenteroKeyContainerHeight {
      padding-bottom: 0px !important;
    }
  }
}
/* @media screen and (min-height: 641px) {
	 @media screen and (min-width: 960px) and (orientation: landscape) {
    .fixedFooter {
      position: fixed;
      bottom: 8px;
      max-width: calc(100% - 185px) !important;
      min-width: calc(100% - 185px) !important;
      width: 100%;
    }
  }
} */