.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.btn {
  display: block;
  margin: 20px 0px;
  width: 100%;
  margin-right: 15px;
  font: var(--zui-typography-subtitle1);
color: #0050F2;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border: 0px solid transparent;
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}
.btn-clear{
  min-width: 120px;
  background: #EDF2F7 !important;
  border: 1px solid #C1CBD9;
  box-sizing: border-box;
  border-radius: 3px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiTypography-root {
  font-size: 14px;
}
.margin-0{
  margin: 0px !important;
}
