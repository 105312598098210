.displayMasterKeyDiv {
    display: block;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 23px);
    height: 100%;
    margin: 5px 18px 18px 18px;
    background-color: white;
    text-align: center;
    color: #353D45;
}

.cursorPoiner {
    cursor: pointer;
}

.fseMasterDeviceTextFormat{
    font-family: "ZEISSFrutigerNextW1G-Bold, sans-serif";
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
}
