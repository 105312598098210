.MuiNativeSelect-select.MuiNativeSelect-select {
    height: 24px !important;
}
.MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup {
    border: 1px solid #c1cbd9 !important;
    box-sizing: border-box !important;
    border-radius: 3px !important;
    background: #f5f7fa !important;
    padding: 4px !important;
}
.options {
    border: 1px solid #c1cbd9 !important;
    box-sizing: border-box !important;
    border-radius: 3px !important;
    background: #f5f7fa !important;
    padding: 4px !important;
}
.MuiListItem-root.Mui-selected {
    width: 350px;
    line-height: 20px;
    height: 32px;
    font: var(--zui-typography-body);
    background: #f5f7fa !important;
}
.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.MuiFormControl-root {
    width: 100% !important;
}
option{
    width: 100%;
     padding: 0px;
     color: #828D9E;
}
.MuiNativeSelect-select:focus {
    background-color: white !important ;
}

.data-protection {
    border-bottom: 1px solid #626C7A;
    text-decoration: none !important;
}
a:hover{
    background: transparent !important;
}

@media screen and (max-height: 580px) {
    @media screen and (min-width: 960px) {
    #loginFooter{
      position: relative !important;
      bottom: 0px;
    }
  }
  }