@font-face {
  font-family: "ZEISSFrutigerNextW1G-Bold";
  src: url("./assets/fonts/ZEISSFrutigerNextW1G-Bold.woff2") format("woff2"),
    url("./assets/fonts/ZEISSFrutigerNextW1G-Bold.woff") format("woff"),
    url("./assets/fonts/ZEISSFrutigerNextW1G-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "ZEISSFrutigerNextW1G-Light";
  src: url("./assets/fonts/ZEISSFrutigerNextW1G-Light.woff2") format("woff2"),
    url("./assets/fonts/ZEISSFrutigerNextW1G-Light.woff") format("woff"),
    url("./assets/fonts/ZEISSFrutigerNextW1G-Light.ttf") format("truetype");
}

@font-face {
  font-family: "ZEISSFrutigerNextW1G-Reg";
  src: url("./assets/fonts/ZEISSFrutigerNextW1G-Reg.woff2") format("woff2"),
    url("./assets/fonts/ZEISSFrutigerNextW1G-Reg.woff") format("woff"),
    url("./assets/fonts/ZEISSFrutigerNextW1G-Reg.ttf") format("truetype");
}
@font-face {
  font-family: "ZEISSFrutigerNextW1G-Medium";
  src: url("./assets/fonts/ZEISSFrutigerNextW1G-Medium.woff2") format("woff2"),
    url("./assets/fonts/ZEISSFrutigerNextW1G-Medium.woff") format("woff"),
    url("./assets/fonts/ZEISSFrutigerNextW1G-Medium.ttf") format("truetype");
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-family: "ZEISSFrutigerNextW1G-Regular", "ZEISSFrutigerNextW1G-Bold",
    "ZEISSFrutigerNextW1G-light", sans-serif;
    /* -webkit-transform: translate3d(0,0,0); */
}
body {
  margin: 0;
  font: var(--zui-typography-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #edf2f7;
}
/* .rootDiv {
  font-family: "ZEISSFrutigerNextW1G-Regular", "ZEISSFrutigerNextW1G-Bold", "ZEISSFrutigerNextW1G-light", sans-serif;
} */
@media screen and (max-width: 959px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 960px) {
  @media screen and (min-height: 360px) {
    body {
      position: fixed;
    }
  }
  @media screen and (max-height: 359px) {
    body {
      position: relative;
    }
  }
}

@media screen and (min-height: 320px) {
  @media only screen and (max-width: 959px) {
    .rootDiv {
      width: 100%;
      height: 100vh;
      position: relative;
      min-width: 320px;
      min-height: 470px;
    }
  }
  @media only screen and (min-width: 960px) {
    .rootDiv {
      width: 100%;
      height: 100vh;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

a:active > a:hover {
  background-color: inherit;
}
a:hover {
  background-color: #353d45;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="tel"] {
  -moz-appearance: textfield;
  font: var(--zui-typography-body);
}

input[type="text"] {
  border-radius: 0px;
}
input[type="text"]:disabled {
  border-bottom: 1px solid #828d9e;
  background-color: #ffffff;
  color: #828d9e;
  -webkit-text-fill-color: #828d9e;
  opacity: 1;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  padding: 5px;
  background: #ffffff;
  -webkit-overflow-scrolling: touch;
  /* -webkit-transform: translate3d(0,0,0); */
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); */
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px #626c7a;
  box-shadow: inset 0 0 6px #626c7a;
}
