#shadow-root > #menubar-item {
  height: calc(var(--zui-gu) * 4) !important;
}

:host(:active) #menubar-item {
  height: calc(var(--zui-gu) * 4) !important;
}

:host(:hover) #menubar-item {
  height: calc(var(--zui-gu) * 4) !important;
}

zui-interactive-icon > zui-icon-arrow-filled-arrow-filled-up {
  display: none;
}
:host([name="icCode"]) {
  display: none;
}

#container:not(.touch) #arrow-Container {
  display: none !important;
}
:host([size="m"]) {
  height: auto;
}

table thead th {
  position: sticky;
  top: 0;
  z-index: 9 !important;
}

@media screen and (max-height: 639px) {
  @media screen and (min-width: 960px) {
    .divAdminDeviceListContainerHeight {
      min-height: calc(100% - 116px);
      max-height: calc(100% - 116px);
      overflow-y: auto;
    }
    @media screen and (min-height: 600px) {
      .divAdminDeviceListContainerHeight {
        min-height: calc(100% - 116px);
        max-height: calc(100% - 116px);
        overflow-y: auto;
      }
    }
    @media screen and (max-height: 599px) {
      .divAdminDeviceListContainerHeight {
        max-height: calc(100% - 116px);
        overflow-y: auto;
      }
    }
  }
  @media screen and (min-width: 1113px) {
    @media screen and (min-height: 600px) {
      .divAdminDeviceListContainerHeight {
        min-height: calc(100% - 116px);
        max-height: calc(100% - 116px);
        overflow-y: auto;
      }
    }

    @media screen and (max-height: 599px) {
      .divAdminDeviceListContainerHeight {
        height: 100%;
        max-height: calc(100% - 116px);
        overflow-y: auto;
      }
    }
  }
}
