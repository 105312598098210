ol li {
  list-style: inherit;
}

ul li {
  list-style: inherit;
}
.deviceNameContainer {
  margin: 8px 8px 4px 8px;
  width: 98.5%;
  background: var(--zui-color-gs-10);
  display: flex;
  user-select: none;
}
.headParagraphUpper {
  padding: 18px 0px 18px 0px;
  text-transform: uppercase;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 0;
  color: #1a2329;
  font: var(--zui-typography-h4);
}
.headSpan {
  padding: 18px 0 18px 16px;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #353d45;
  font: var(--zui-typography-body);
  /* margin: auto; */
}
.headContainer {
  margin: 8px 8px 4px 8px;
  width: 98.5%;
  background: var(--zui-color-gs-10);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headParagraph {
  padding: 20px;
  margin: 0;
  size: 16px;
  line-height: 24px;
  font-weight: 400;
  align-items: justified;
}
.bodyContainer {
  margin: 0px 8px 0px 8px;
  width: 98.5%;
  height: calc(100% - 144px);
  overflow: auto;
  padding: 16px 0 16px 24px;
  color: #353d45;
  background: var(--zui-color-gs-10);
}
.rte {
  height: calc(100% - 144px);
}
.deviceBodyContainer {
  background: var(--zui-color-gs-10);
  margin: 2px 8px 0px 8px;
  width: 98.5%;
  height: calc(100% - 190px);
  overflow: auto;
}
.deviceKeytypeBodyContainer {
  background: var(--zui-color-gs-10);
  margin: 2px 8px 0px 8px;
  width: 98.5%;
  height: calc(100% - 264px);
  overflow: auto;
}
.viewDeviceBodyContainer {
  background: var(--zui-color-gs-10);
  margin: 0px 8px 8px 8px;
  width: 98.5%;
  height: calc(100% - 280px);
  overflow: auto;
}
.addDeviceBodyContainer {
  background: var(--zui-color-gs-10);
  margin: 0px 8px 8px 8px;
  width: 98.5%;
  height: calc(100% - 149px);
  overflow: auto;
}
.addDeviceBodyDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 24px;
}
.addDeviceBlocks {
  width: 20%;
  height: 40px;
}
.addDeviceBodyInnerDiv {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 0 24px;
}
.addDeviceInnerBlocks {
  width: 20%;
}
.addNewVersionContainer {
  background: var(--zui-color-gs-10);
  margin: 0px 8px 8px 8px;
  width: 98.5%;
  height: calc(100% - 213px);
  overflow: auto;
}
.deviceAddNewVersionDiv {
  margin: 8px;
  width: calc(100% - 16px);
  max-width: 1080px;
  height: 56px;
  background: var(--zui-color-gs-10);
  display: block;
  position: relative;
  padding: 8px 16px 8px 24px;
  user-select: none;
}
.addNewSkillsetContainer {
  background: var(--zui-color-gs-10);
  margin: 1px 8px 8px 8px;
  width: 98.5%;
  height: calc(100% - 196px);
}
.addNewSkillsetInnerdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 0 0 24px;
}
.addNewSkillsetBox {
  width: 33.3%;
}
.addNewSkillsetForm {
  width: 100%;
}
@media screen and (min-width: 1023px) and (max-width: 1181px) {
  .addNewSkillsetBox {
    width: 75%;
  }
  .deviceAddNewVersionDiv {
    max-width: 980px;
  }
}
/* portrait mode ipad */
@media screen and (min-height: 1023px) and (max-height: 1181px) {
  .addNewSkillsetInnerdiv {
    display: block;
  }
}
@media screen and (max-width: 959px) {
  .addNewSkillsetBox,
  .addNewSkillsetForm {
    width: 400px;
  }
}
@media screen and (max-width: 1180px) {
  .addDeviceBodyDiv {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 36px 8px;
  }
  .addDeviceBlocks {
    width: 45%;
  }
  .addDeviceInnerBlocks {
    width: 24.5%;
  }
}
@media screen and (max-height: 587px) {
  .deviceBodyContainer {
    height: 324px;
  }
  .deviceKeytypeBodyContainer {
    height: 251px;
  }
  .viewDeviceBodyContainer {
    height: 235px;
  }
  .addNewVersionContainer {
    height: 303px;
  }
  .addNewSkillsetContainer {
    height: 320px;
  }
}
@media screen and (min-height: 1024px) {
  .rte {
    height: 808px;
  }
  .bodyContainer {
    height: 808px;
  }
  .deviceBodyContainer {
    height: 764px;
  }
  .deviceKeytypeBodyContainer {
    height: 688px;
  }
  .viewDeviceBodyContainer {
    height: 672px;
  }
  .addDeviceBodyContainer {
    height: 804px;
  }
  .addNewVersionContainer {
    height: 740px;
  }
  .addNewSkillsetContainer {
    height: 757px;
  }
}
@media screen and (min-height: 1180px) {
  .rte {
    height: 962px;
  }
  .bodyContainer {
    height: 962px;
  }
  .deviceBodyContainer {
    height: 918px;
  }
  .deviceKeytypeBodyContainer {
    height: 842px;
  }
  .viewDeviceBodyContainer {
    height: 826px;
  }
  .addDeviceBodyContainer {
    height: 959px;
  }
  .addNewVersionContainer {
    height: 895px;
  }
  .addNewSkillsetContainer {
    height: 912px;
  }
}
@media screen and (min-height: 1280px) {
  .addDeviceBodyContainer {
    height: 1059px;
  }
  .addNewVersionContainer {
    height: 995px;
  }
  .addNewSkillsetContainer {
    height: 1012px;
  }
}
.deviceBodyItemDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  border-bottom: 1px solid #c1cbd9;
}
.reqContainer {
  margin: 2px 8px 0px 8px;
  width: 98.5%;
  overflow: auto;
  padding: 12px 0 16px 16px;
  color: #353d45;
  background: var(--zui-color-gs-10);
  height: 100%;
}
.bodyParagraph {
  margin: 0;
  size: 16px;
  line-height: 24px;
  font-weight: 600;
  align-items: justified;
}
.selectContainer {
  margin-top: 16px;
}
.selectContainer.login {
  margin-top: 20px;
}
#templateBody {
  margin-top: 40px;
}
#templateBodyrte {
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 10px;
}
.footerContainer {
  margin: 4px 8px 8px 8px;
  width: 98.5%;
  height: 64px;
  max-height: 64px;
  background: var(--zui-color-gs-10);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: 0px;
  /* padding: 10px; */
  /* width: 84.3%; */
  /* position: fixed; */
}
.footerButton1 {
  margin: 16px 16px 16px 0px;
  width: 80px;
}
.footerButton2 {
  margin: 16px 8px 16px 0px;
  width: 80px;
}
.footerLargeButton1 {
  margin: 16px 16px 16px 0px;
  width: 100px;
}
.footerLargeButton2 {
  margin: 16px 8px 16px 0px;
  width: 100px;
}
.footerBackButton {
  margin: 16px 16px 16px 0px;
  width: 94px;
}
.notificationDiv {
  position: fixed;
  left: 36%;
  bottom: 1%;
  background-color: #262e36;
  border-radius: 3px;
  width: 208px;
  height: 32px;
}
.notificationPara {
  margin-top: 6px;
  margin-left: 8px;
  color: #d1d9e5;
}
zui-button[emphasis="active-primary"]::part(button) {
  border: 1px solid #0029af;
  border-radius: 3px;
}
.divRequestContainer {
  height: calc(100% - 154px);
  bottom: 0px;
}
@media screen and (max-height: 1180px) and (max-width: 820px) {
  .divRequestContainer {
    height: calc(100% - 130px);
    bottom: 0px;
  }
}
@media screen and (max-height: 1024px) and (max-width: 768px) {
  .divRequestContainer {
    height: calc(100% - 130px);
    bottom: 0px;
  }
}
@media screen and (max-height: 720px) {
  .divRequestContainer {
    height: calc(100% - 154px);
    bottom: 0px;
  }
}
@media screen and (max-height: 720px) and (max-width: 959px) {
  .divRequestContainer {
    height: 314px;
    margin: 4px;
  }
}
.deviceContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
@media screen and (min-width: 960px) {
  .deviceContainer {
    width: calc(100% - 184px);
  }
  .notificationDiv {
    left: 50%;
  }
}
.btnSubmit {
  background-color: #0050f2 !important;
  border: 1px solid #0029af;
  color: #fff !important;
  width: 120px;
}
.crossIcon {
  cursor: pointer;
}
.textareaDiv {
  margin-top: 16px;
  height: 83px;
  width: 500px;
}
.textareaBody {
  height: 83px;
  width: 500px;
  padding: 11px 16px;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background-color: #f5f7fa;
  color: #353d45;
}
.attachFileSpan {
  margin: 8px 8px 0 8px;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.requestnotifDiv {
  position: relative;
  width: calc(100% - 14px);
  margin: 0 8px;
  top: 8px;
  border-top: 5px solid #0050f2;
  background-color: #f5f7fa;
  border-radius: 5px;
  /* width: 208px; */
  height: 64px;
  outline: "none";
}
.requestnotifSpan {
  margin: 21px 17px 21px 0px;
  padding: 0;
  color: #353d45;
  position: absolute;
}
.requestImg {
  margin: 21px 17px;
}
.requestCross {
  float: right;
  margin-right: 18px;
  margin-top: 16px;
  position: relative;
}
.emailDiv {
  border-bottom: 1px solid #c1cbd9;
  padding: 7px 7px 7px 0px;
  width: 250px;
}
.emailInput {
  border: none;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353d45;
  display: flex;
  align-items: center;
  width: inherit;
  background-color: white;
  -webkit-opacity: 0.5;
  opacity: 0.5;
}
.emailInput.login {
  color: #353d45;
  opacity: 1;
  -webkit-opacity: 1;
}
@media screen and (max-height: 1180px) and (min-height: 768px) and (max-width: 1180px) and (min-width: 768px) {
  .emailInput {
    opacity: 0.9 !important;
    -webkit-touch-callout: none;
    /* -webkit-text-fill-color: currentColor; */
    -webkit-text-fill-color: #353d45;
  }
}
.emailLabel {
  font-style: normal;
  font-family: Segoe UI;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #626c7a;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  margin-left: 2px;
  width: 250px;
  height: 16px;
}
textarea {
  resize: none;
}
.adminInfoFooterContainer {
  position: relative;
  width: 100%;
  background: white;
  box-shadow: none !important;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
}
.headingUppercaseDiv {
  padding: 18px 0 18px 24px;
  text-transform: uppercase;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 0;
  color: #1a2329;
  font: var(--zui-typography-h4);
}
