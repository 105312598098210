.divAdminDeviceListContainerHeight {
  overflow-x: hidden;
}
table {
  border-spacing: 0em;
  font: var(--zui-typography-body);
  width: 100%;
}
table thead th {
  position: sticky;
  top: 0;
  z-index: 9 !important;
}
/* @media screen and (max-width: 959px) {
  table {
    width: calc(100% - 8px);
    margin: 4px;
  }
}
@media screen and (min-width: 960px) {
  table {
    width: calc(100% - 1px);
  }
} */
thead > tr > th {
  border: 1px solid #c1cbd9;
  font-size: 10px;
  line-height: 12px;
  text-align: start;
  text-transform: uppercase;
  color: #626c7a;
  font-weight: bold;
  margin: 5px;
  background-color: #edf2f7;
  font: var(--zui-typography-label2);
}
tbody {
  background-color: white;
}
tbody > tr > td {
  border-bottom: 1px solid #c1cbd9;
  margin: 10px 5px;
}
@media screen and (max-width: 959px) {
  td {
    max-width: 200px;
  }
}
@media screen and (min-width: 960px) {
  td {
    width: 250px;
  }
}

.cursorPoiner {
  cursor: pointer;
}
.historyfixedFooter {
  position: fixed;
}
@media screen and (min-width: 1025px) {
  .historyViewIcon,
  .downloadKeyIcon {
    cursor: pointer;
  }
}
@media screen and (max-width: 1024px) {
  .historyViewIcon,
  .downloadKeyIcon {
    cursor: auto;
  }
}

input:focus,
select:focus {
  outline: none;
}

.react-datepicker__input-container > input {
  width: inherit;
  border: none;
  border-bottom: 1px solid #c1cbd9;
  padding-bottom: 5px;
  background: url("../../assets/icons/calendar-icon.png") right;
  background-repeat: no-repeat;
}

.react-datepicker__navigation {
  -webkit-text-fill-color: #ccc;
  opacity: 1;
}
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon:before,
.react-datepicker__year-read-view--down-arrow {
  -webkit-text-fill-color: #ccc;
  opacity: 1;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationItem {
  background: #fff;
  padding: 10px 15px;
  position: relative;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: blue;
  cursor: pointer;
}

.paginationItem.active {
  color: blue;
  pointer-events: none;
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}

/* pagination list block style */
.MuiPaginationItem-page.Mui-selected {
  background-color: transparent !important;
  border-bottom: 2px solid #0050f2;
  border-radius: 0px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
@media screen and (max-width: 1180px) {
  .formSelectBtnDiv {
    padding-top: 4px;
  }
}
