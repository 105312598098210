.divListContainer{
    height: 100%;
    padding: 0px 10px;
}

.cursorPoiner {
    cursor: pointer;
}

.highlight{
    border: 2px solid #008bd0!important;
}

.onedayDeviceTextFormat {
    font-family: "ZEISSFrutigerNextW1G-Bold, sans-serif";
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
}

.modalbtn:hover {
    background-color: #EDF2F7;
    border-radius: 3px;
    cursor: pointer;
  }


.MuiButtonBase-root.Mui-disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}


.MuiTypography-displayBlock {
    font-size: 24px;
}
.MuiButton-root{
    text-transform: capitalize !important;
}
li{
    list-style: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #828D9E;
    opacity: 1; /* Firefox */
    font-size: 12px !important;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #828D9E;
    font-size: 12px !important;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #828D9E;
    font-size: 12px !important;
  }



