.MuiPaper-rounded {
  border-radius: 0px !important;
}
.raiseReqLabel {
  margin-top: 17px;
  margin-bottom: 27px;
  margin-right: 11px;
  width: 149px;
  height: 20px;
  font-style: normal;
  font-family: Segoe UI;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #edf2f7;
}